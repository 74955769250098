import React, { useEffect, useRef } from 'react';
import Jumbotron from 'react-bootstrap/Jumbotron';
import Carousel from 'react-bootstrap/Carousel';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import Row from 'react-bootstrap/Row';
import Col from 'react-bootstrap/Col';
import { textIntro, bgColor, scroll1, scroll2 } from './animate';
import { Link as LinkRoute} from "react-router-dom";
import { images } from './images';
import { faImages } from '@fortawesome/free-regular-svg-icons';

function Hero () {

  // Refs for GSAP animation
  let intro = useRef();
  let hero = useRef();
  
  // Animation for scrolls and book name
  useEffect(() => {
    textIntro(intro.current);
    bgColor(hero.current);
    scroll1(hero.current);
    scroll2(hero.current);
    window.scrollTo(0, 0);
  }, []);
  const imageCards = images.otherWorks.map((image, index) => (
    <img key={image} className="image-card" src={image} />
  ));
  return (
    <>
    <Jumbotron className="hero text-center" ref={hero} style={{ backgroundImage: `linear-gradient(rgba(101,157,189, 0.3), rgba(101,157,189, 0.3)), url(${process.env.PUBLIC_URL + '/assets/header.png'})` }}>
      <h1 className="first">Ashfaq Ishaq</h1>
      <h2 className="intro" ref={intro}>Anti-Children</h2>
      <LinkRoute to="/book" id="fancyBtn"><svg><rect></rect></svg><span>Peek inside this new book</span></LinkRoute>
      {/* Add Bio here */}
      <Row className="mx-auto" id="author">
        <div className="text-divider second">About Ashfaq</div>
        <Col xs={12} sm={{ span: 10, offset: 1 }} lg={{ span: 6, offset: 0 }} className="my-auto">
          <img src={process.env.PUBLIC_URL + '/assets/author.jpg'} alt="" />
          <p><br/><br/></p>
          <img src={process.env.PUBLIC_URL + '/assets/author2.jpg'} alt="" />
        </Col>
        <Col xs={12} lg={6} className="my-auto">
          <div className="about">
          <p> I am a civil sector leader and a multidisciplinary researcher whose work has appeared across various national and international publications, including Finance & Development, Institutional Investor, The Lancet, the Journal of Conflict Resolution, and Dynamische Psychiatrie. My first book, which I co-authored, was published by the Oxford University Press in 1987. <br/><br/> The ideas that percolate in my mind every morning make me believe I am a genius. By nightfall, I realize I am a sentimental fool. Instead of making money every waking moment, I have squandered my days since 1997 in empowering children to recast this wounded world into a wondrous one. This Sisyphean mission became my crucifix, my crescent moon, my six-point star. I lost all I had. I do not now qualify for social security either. Perhaps my reward lies in the five million children who benefitted from the programs I have designed and organized in schools worldwide. Or in the million or more who learned something valuable from the ChildArt magazine I have published ad-free since 1998. Or in two to three hundred thousand who participated in the World Children’s Festivals or child art exhibitions that I have curated and produced. Or in the few scores for whom I finagled the opportunity to address business and political leaders at conferences where I have arranged and moderated children’s panels. Better still, my reward may lie in you: In what you learn and how you improve. <br/><br/><a href="https://www.linkedin.com/in/ashfaqishaq/"> Connect with Ashfaq - <FontAwesomeIcon icon={["fab", "linkedin"]} size="lg" color="white" /></a></p>
          </div>
        </Col>
      </Row>
      {/* Add extra testiminials here */}
      <Row className="mx-auto" id="testimonials">
        <div className="text-divider">Testimonials</div>
        <Col xs={12} lg={6} className="my-auto">
          <Carousel className="testimonials">
            <Carousel.Item>
              <p>Ashfaq is a brilliant mover and shaker focused on world peace. Ashfaq well deserves the many honors and awards he has received but the most flattering award would be using his model and copying his efforts in adult groups around the world. Imagine the possibilities as Ashfaq Ishaq does!</p>
              <p>- Bobbi Stoll, Art Therapy Pioneer and Honorary Life Member of the Southern California Art Therapy Association</p>
            </Carousel.Item>
            <Carousel.Item>
              <p>A true pioneer of vision and dedication Ashfaq has worked against many odds to bring a new understanding of the importance of the Arts and creativity to Governments educationalists parents and children alike. He deserves a Noble Peace prize for this achievement.</p>
              <p>- Shona Hammond Boys, National Director at New Zealand Childrens Art House Foundation</p>
            </Carousel.Item>
            <Carousel.Item>
              <p>Dr. Ishaq is the most respectable person I have ever met. He is a great leader, strategist, and a "policy artist" for creation. Working with Dr. Ishaq is truly a pleasure, and an invaluable learning process not only professionally, but also personally.</p>
              <p>- Yuxuan Chen, Rhodes Scholar, University of Oxford</p>
            </Carousel.Item>
            <Carousel.Item>
              <p>Ashfaq is an amazing creative man who is more than just a visionary. He is someone without limits or a "dis" comfort zone - he's at home wherever he happens to be because he knows that is where he's meant to be. Ashfaq has a down to earth and gentle soul that can coax hidden talents and the spirit of a child has never left this man among giants.</p>
              <p>- Janet McElligott, President at McElligott International</p>
            </Carousel.Item>
            <Carousel.Item>
              <p>I can’t think of a person more qualified to attend to the education and cultivation of our children and young people at this crucial stage in their lives than Ashfaq. He helps them to grow, fly, dream, and aspire to a better world and better life at the ideal time.</p>
              <p>- D. Paul Schafer, Director, World Culture Project</p>
            </Carousel.Item>
          </Carousel>
        </Col>
        <Col xs={12} lg={6} className="my-auto">
          <img src={process.env.PUBLIC_URL + '/assets/people.jpg'} alt="Dr.Ishaq with a group of Mauri kids" />
        </Col>
      </Row>
      <Row className="mx-auto" id="otherworks">
        <div className="text-divider">Other Works</div>
      </Row>  
      <Row>
        <Carousel className="otherW" slide={false} fade={false}>
          {images.otherWorks.map((image,index) => (
            <Carousel.Item key={image}>
              <a href={images.otherUrls[index]} target="_blank">
              <img
                className="d-block w-100"
                src={image}
                alt={'slide ' + index}
              /></a>
            </Carousel.Item>))};
        </Carousel>
      </Row>
    </Jumbotron>
    </>
  )
}

export default Hero;