export const images = {
  // Add image urls for "olympiad" carousel here
  olympiad: [
    process.env.PUBLIC_URL + '/assets/olympiad/1.jpg',
    process.env.PUBLIC_URL + '/assets/olympiad/2.jpg',
    process.env.PUBLIC_URL + '/assets/olympiad/3.jpg',
    process.env.PUBLIC_URL + '/assets/olympiad/4.jpg',
    process.env.PUBLIC_URL + '/assets/olympiad/5.jpg',
    process.env.PUBLIC_URL + '/assets/olympiad/6.jpg',
    process.env.PUBLIC_URL + '/assets/olympiad/7.jpg',
    process.env.PUBLIC_URL + '/assets/olympiad/8.jpg',
    process.env.PUBLIC_URL + '/assets/olympiad/9.jpg',
    process.env.PUBLIC_URL + '/assets/olympiad/10.jpg',
  ],
  // Add image urls for "works" lightbox and gallery here
  works: [
    process.env.PUBLIC_URL + '/assets/works/1.jpg',
    process.env.PUBLIC_URL + '/assets/works/2.jpg',
    process.env.PUBLIC_URL + '/assets/works/3.jpg',
    process.env.PUBLIC_URL + '/assets/works/4.jpg',
    process.env.PUBLIC_URL + '/assets/works/5.jpg',
    process.env.PUBLIC_URL + '/assets/works/6.jpg',
    process.env.PUBLIC_URL + '/assets/works/7.jpg'
  ],
  credit: [
    'Aishling Kelly, age 11, Arizona',
    'Arjaa Raghu, age 11, Illinois',
    'Chung Kei Ting, age 10, China',
    'Emily Levine, age 11, Florida',
    'Joseph Dexo Yowom, age 11, Ghana',
    'Kristina Gechevska, age 11, Bulgaria',
    'Kseniia Butenko, age 11, Ukraine'
  ],
  otherWorks:[
    process.env.PUBLIC_URL + '/assets/otherWorks/conflict.jpg',
    process.env.PUBLIC_URL + '/assets/otherWorks/cybertherapy.jpg',
    process.env.PUBLIC_URL + '/assets/otherWorks/finance.jpg',
    process.env.PUBLIC_URL + '/assets/otherWorks/institutional.jpg',
    process.env.PUBLIC_URL + '/assets/otherWorks/journalUrban.jpg',
    process.env.PUBLIC_URL + '/assets/otherWorks/lancet.jpg',
    process.env.PUBLIC_URL + '/assets/otherWorks/steamJournal.jpg',
    process.env.PUBLIC_URL + '/assets/otherWorks/UNESCO.jpg'
  ],
  otherUrls:[
    "https://journals.sagepub.com/doi/10.1177/0022002787031001007",//conflict
    "https://journals.sagepub.com/doi/10.1177/0022002787031001007",//conflict, needs to be pdf of cybertherapy
    "https://www.imf.org/external/pubs/ft/fandd/2001/09/ishaq.htm",//finance
    "https://www.imf.org/external/pubs/ft/fandd/2001/09/ishaq.htm",//finance, needs to be institutional
    "http://www.cujucr.com/downloads/Individual%20Articles/3/vol3%20Ashfaq%20M.%20Ishaq.pdf",//JournalUrban
    "https://www.thelancet.com/pdfs/journals/lancet/PIIS0140673606699157.pdf",//lancet
    "https://scholarship.claremont.edu/steam/vol3/iss2/3/",//Steamjournal
    "https://www.unescoejournal.com/wp-content/uploads/2020/01/1-6-prosperity-peace.pdf"//UNESCO
  ]
}